<template>
  <div>
    <div class="p-5">
      <h3>Naplánuj výrobu</h3>
      <ul class="list-unstyled">
        Zákazník: <b>{{ data.customer.name }}</b><br>
        Zadal: <b>{{ data.user.full_name }}</b>
        <li class="" v-for="(value, key) in data.orderProducts" v-bind:key="key">
          Produkt: <b>{{ value.product.name }}</b><br>
          Rok: <select class="form-select" name="year" v-model="value.year">
          <option v-for="year in 3" v-bind:key="year" :value="new Date().getFullYear() + year - 1">
            {{ new Date().getFullYear() + year - 1 }}
          </option>
        </select>
          <div v-if="value.year">
            Týždeň:
            <select class="form-select" name="week" v-model="value.week">
              <option v-for="week in weeksInYear(value.year)" v-bind:key="week" :value="week.weekNo">
                {{ week.weekNo }}. Od: {{ week.start }}
                Do: {{ week.end }}
              </option>
            </select>
          </div>
        </li>
      </ul>
    </div>
    <button class="btn btn-success" @click="storePlannedOrder(data)">Naplánuj</button>
    <hide-modal-button/>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex"
import HideModalButton from "./HideModalButton";

export default {
  name: "PlanOrder",
  props: ['data'],
  components: {
    HideModalButton,
  },
  methods: {
    ...mapActions(['storePlannedOrder']),
    isFuture(week, year) {
      var dates = this.getDateOfISOWeek(week, year)
      var current = new Date();
      console.log(dates, current)
      dates.start
      return true
    },
    getWeekNumber: function (d) {
      d = new Date(+d);
      d.setHours(0, 0, 0, 0);
      d.setDate(d.getDate() + 4 - (d.getDay() || 7));
      var yearStart = new Date(d.getFullYear(), 0, 1);
      var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7)
      return [d.getFullYear(), weekNo];
    },
    weeksInYear: function (year) {
      var d = new Date(year, 11, 31);
      var week = this.getWeekNumber(d)[1];
      var count = week === 1 ? 52 : week;
      var list = [];
      var current = new Date();

      for (let i = 1; i <= count; i++) {
        var dates = this.getDateOfISOWeek(i, year)
        if (current < dates.start || current < dates.end) {
          list.push({
            weekNo: i,
            start: dates.start.toLocaleString('sk', {
              month: "numeric", day: "numeric",
            }),
            end: dates.end.toLocaleString('sk', {
              month: "numeric", day: "numeric",
            })
          });
        }
      }
      return list;
    },
  },
  computed: {
    ...
        mapState(['orders'])
  }
  ,
}
</script>

<style scoped>

</style>