<template>
  <div class="p-5 position-absolute top-0 start-0 h-100 w-100" id="modal">
    <component v-bind:is="component" v-bind:data="data" class="bg-white position-absolute top-50 start-50 translate-middle"/>
  </div>
</template>

<script>
import OrderDetail from "./OrderDetail";
import PlanOrder from "./PlanOrder";
import EconomyOrder from "./EconomyOrder";
import BatchOrder from "./BatchOrder";
import ArchiveOrder from "./ArchiveOrder";
import {mapMutations} from "vuex";
export default {
  props: ['component', 'data'],
  components: {
    OrderDetail,
    PlanOrder,
    EconomyOrder,
    BatchOrder,
    ArchiveOrder,
  },
  name: "Modal",
  methods: {
    ...mapMutations(['toggleModal'])
  }
}
</script>

<style scoped>
#modal {
  background: rgba(200,200,200,0.5);
}
</style>