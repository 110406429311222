<template>
  <div class="bg-white p-5">
    <div>
      <ul class="list-style-unstyled">
        Customer: {{ data.customer.name }}<br>
        Zadal: {{ data.user.attributes.name }}
        <p v-for="(productOrder, id) in data.orderProducts" v-bind:key="id">
        <span :class="productOrder.amount_created >= productOrder.estimated_amount ? 'font-weight-bold' : 'text-default'">
            {{ productOrder.product.name }}: {{ productOrder.amount_created }}/{{ productOrder.estimated_amount }},
            planned: {{productOrder.year }}/{{ productOrder.week}}
        </span>
        </p>
        <select v-model="batch" v-if="batches.length > 0">
          <option v-for="(value, key) in filteredBatches" v-bind:key="key" :value="value.batch_id">
            {{ value.identifier }} - {{ value.product.name }} {{value.product.attributes.type}}/{{value.product.attributes.recipe_number}} - ({{ value.amount_left }})
          </option>
        </select>
        <div v-else> Nemáte žiadne vyrobené šarže pre tieto produkty</div>
        <span v-if="batch">
          Amount:<input type="number" v-model="amount"
                        :placeholder="batchAmount">
          <p v-if="enoughMaterial" class="text-danger">Nedostatok materialu na sklade!</p>
        </span>
      </ul>
    </div>
    <button class="btn btn-success"
            @click="storeBatchOrder({order_id: data.order_id, batch_id: batch, amount: amount})">Plan
    </button>
    <hide-modal-button/>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import HideModalButton from "./HideModalButton";

export default {
  name: "BatchOrder",
  components: {HideModalButton},
  props: ['data'],
  data: function () {
    return {
      batch: null,
      amount: "",
    }
  },
  methods: {
    ...mapActions(['storeBatchOrder', 'getBatches'])
  },
  computed: {
    enoughMaterial(){
      return Number(this.amount) > this.batchMap[this.batch].amount_left + this.batchAmount
    },
    batchAmount(){
      var amounts = this.batchMap[this.batch].ordersAmounts;
      if(amounts !== null){
        return amounts[this.data.order_id] === undefined ? 0 : amounts[this.data.order_id];
      }
      return 0
    },
    filteredBatches() {
      if(Array.isArray(this.batches)){
        return this.batches.filter(b => {
          return this.productIds.includes(b.product.product_id)
        })
      }
      return [];
    },
    ...mapState(['orders', 'batches']),
    productIds: function () {
      return this.data.orderProducts.map(op => op.product.product_id)
    },
    batchMap: function () {
      var tmp = {}
      this.batches.forEach((b) => {
        tmp[b.batch_id] = b;
      })
      return tmp;
    },
    estimatesMap: function () {
      var tmp = {}
      this.data.orderProducts.forEach((b) => {
        tmp[b.product.product_id] = b.estimated_amount;
      })
      return tmp;
    }
  },
  created() {
    this.getBatches()
  }
}
</script>

<style scoped>

</style>