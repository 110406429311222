<template>
  <div>
    <div @click="setLogin">Login</div>
    <div @click="setRegister">Register</div>
    <form>
      Email: <input type="text" v-model="email">
      Password: <input type="password" v-model="password">
      <div v-if="register">
        Password_Confirm: <input type="password" v-model="passwordConfirm">
        Name: <input type="text" v-model="name">
        Surname: <input type="text" v-model="surname">
        Phone Number: <input type="text" v-model="phone">

      </div>
      <button @click="action">{{ register ? "Register" : "Login" }}</button>
    </form>
  </div>
</template>

<script>
import {mapActions, mapState, mapMutations} from "vuex"

export default {
  name: "LoginPage",
  props: {
    msg: String,
  },
  data: function () {
    return {
      ok: "ok",
      login: true,
      register: false,
      email: "",
      password: "",
      passwordConfirm: "",
      name: "",
      surname: "",
      phone: "",
    }
  },
  methods: {
    setLogin() {
      this.login = true;
      this.register = false;
      this.passwordConfirm = ""
    },
    setRegister() {
      this.login = false;
      this.register = true;
    },
    action(e) {
      e.preventDefault();
      var data = {
        email: this.email,
        password: this.password
      }
      if (this.login) {
        this.storeLogin(data)
      }
      if (this.register) {
        data.attributes = {
          name: this.name,
          surname: this.surname,
          phone_number: this.phone,
        }

        data.password_confirmation = this.passwordConfirm
        this.storeRegister(data)
      }

    },
    ...mapActions([
      "storeLogin",
      "storeRegister",
    ]),
    ...mapMutations(["setCurrentUser"])

  },
  computed: {
    ...mapState(['customers'])  // assuming you are using namespaced modules
  },
  mounted: function () {
    this.setCurrentUser({});
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
