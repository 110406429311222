<template>
  <div class="p-5">
    c. faktury: <input type="text" v-model="attributes.invoice_number"><br>
    Dodaci list: <input type="text" v-model="attributes.delivery_bill"><br>
    <button @click="archiveOrder(withAttributes)" class="btn btn-success">Ulozit a archivovat</button>
    <hide-modal-button/>
  </div>
</template>

<script>
import {mapActions} from "vuex";
import HideModalButton from "./HideModalButton";

export default {
  name: "ArchiveOrder",
  components: {HideModalButton},
  data: function () {
    return {
      attributes: {
        invoice_number: "",
        delivery_bill: "",
      }
    }
  },
  props: ['data'],
  methods: {
    ...mapActions(['archiveOrder'])
  },
  computed: {
    withAttributes(){
      return {
        order_id: this.data.order_id,
        attributes: this.attributes
      }
    }
  }
}
</script>

<style scoped>

</style>