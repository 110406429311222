<template>
  <div>
    {{ archivedOrders }}
    <table class="table" v-if="archivedOrders.length > 0">
      <tr>
        <th>Cislo zakazky</th>
        <th>Customer</th>
        <th>products</th>
        <th>Estimated delivery</th>
        <th>Placed by</th>
      </tr>

      <tbody>
      <tr v-for="order in archivedOrders" v-bind:key="order.order_id">
        <td>
          {{order.economy_order.identifier}}
        </td>
        <td>
          {{order.customer.name}}
        </td>
        <td v-for="product in order.orderProducts" v-bind:key="product.product.product_id">
          {{product.product.product_id}}
        </td>
        <td>
          {{order.estimated_delivery}}
        </td>
        <td>
          {{order.user.full_name}}
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "ArchivedOrders",
  data: function () {
    return {
      showPlan: false,
      showEconomy: false,
      planOrder: null,
      economyOrder: null,
      batchOrder: null,
      orderDetail: null,
      modalComponent: "",
      modalData: null,
    }
  },
  methods: {
    setOrderToModal(id, component) {

      var isMouseEvent = id instanceof MouseEvent
      this.modalData = isMouseEvent ? null : JSON.parse(JSON.stringify(this.orders[id]));
      this.toggleModal(!isMouseEvent);
      this.modalComponent = component;
    },
    isFinished(orderProducts) {
      return !orderProducts.map((o) => {
        return o.amount_created >= o.estimated_amount
      }).includes(false);
    },
    ...mapActions(["getArchivedOrders"]),
    ...mapMutations(["toggleModal"]),
  },
  computed: {
    ...mapState(["archivedOrders", "loggedUser", "showModal"]),
  },
  mounted: function () {
    this.getArchivedOrders()
  }
};
</script>
