<template>
  <button class="btn btn-secondary" @click="toggleModal(false)">Zavrieť</button>
</template>

<script>
import {mapMutations} from "vuex"
export default {
  name: "HideModalButton",
  methods: {
    ...mapMutations(['toggleModal'])
  }
}
</script>

<style scoped>

</style>