<template>
  <div>
    <store-order v-if="canManageOrders"/>
    <modal v-bind:component="modalComponent" v-bind:data="modalData" v-if="this.showModal"/>
    <table class="table" v-if="orders.length>0">
      <tr>
        <th>Cislo zakazky</th>
        <th>Customer</th>
        <th>products</th>
        <th>Estimated delivery</th>
        <th>Placed by</th>
        <th v-if="canPlanOrder || canManageOrders || canPlaceEconomy || canSetBatchToOrder">actions</th>
      </tr>

      <tbody>
      <tr v-for="(order, key) in orders" v-bind:key="key"
          :class="isFinished(order.orderProducts) ? 'bg-success' : 'bg-transparent'"
          @click="setOrderToModal(key, 'order-detail')">

        <td>{{ order.economy_order !== undefined ? order.economy_order.identifier : " - " }}</td>
        <td>{{ order.customer.name }}</td>
        <td><p v-for="(productOrder, id) in order.orderProducts" v-bind:key="id">
          <span
              :class="productOrder.amount_created >= productOrder.estimated_amount ? 'font-weight-bold' : 'text-default'">
            {{ productOrder.product.name }}: {{ productOrder.amount_created }}/{{ productOrder.estimated_amount }},
            <span v-if="productOrder.year">planned: {{ productOrder.year }}/{{ productOrder.week }}</span>
        </span>
        </p>
        </td>
        <td>{{ order.estimated_delivery }}</td>
        <td>{{ order.user.attributes.name }} {{ order.user.attributes.surname }}</td>
        <td v-if="canPlanOrder || canManageOrders || canPlaceEconomy || canSetBatchToOrder" @click.stop>
          <button v-if="order.state === 'placed' && canPlanOrder" class="btn btn-primary"
                  @click="setOrderToModal(key,'plan-order')">
            Naplánovať výrobu!
          </button>
          <button v-else-if="canPlaceEconomy && order.state==='planned' && !order.hasOwnProperty('economy_order')"
                  class="btn btn-primary"
                  @click="setOrderToModal(key, 'economy-order')">zaeviduj do economy!
          </button>
          <button v-else-if="isFinished(order.orderProducts) && canManageOrders" @click="setOrderToModal(key, 'archive-order')">ukoncit a
            archivovat
          </button>
          <button v-else-if="canSetBatchToOrder" @click="setOrderToModal(key, 'batch-order')">Add batch</button>
          <p v-else>s</p>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapMutations, mapState} from "vuex";
import * as permissionsDefinitions from '../../helpers/permissions'
import Modal from "../../components/Modal";
import StoreOrder from "../../components/StoreOrder";

export default {
  name: "Orders",
  data: function () {
    return {
      showPlan: false,
      showEconomy: false,
      planOrder: null,
      economyOrder: null,
      batchOrder: null,
      orderDetail: null,
      modalComponent: "",
      modalData: null,
    }
  },
  components: {
    StoreOrder,
    Modal
  },
  methods: {
    setOrderToModal(id, component) {

      var isMouseEvent = id instanceof MouseEvent
      this.modalData = isMouseEvent ? null : JSON.parse(JSON.stringify(this.orders[id]));
      this.toggleModal(!isMouseEvent);
      this.modalComponent = component;
    },
    isFinished(orderProducts) {
      return !orderProducts.map((o) => {
        return o.amount_created >= o.estimated_amount
      }).includes(false);
    },
    ...mapActions(["getOrders", "archiveOrder"]),
    ...mapMutations(["toggleModal"]),
  },
  computed: {
    ...mapState(["orders", "loggedUser", "showModal"]),
    canPlanOrder() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.PLAN_ORDER_TOKEN_SCOPE)
      } else {
        return false;
      }
    },
    canManageOrders() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_ORDERS_TOKEN_SCOPE)
      } else {
        return false;
      }
    },
    canPlaceEconomy() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.PLACE_ECONOMY_ORDER_TOKEN_SCOPE)
      } else {
        return false;
      }
    },
    canSetBatchToOrder() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_ORDERS_TOKEN_SCOPE) ||
            this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_BATCHES_TOKEN_SCOPE)
      } else {
        return false;
      }
    }
  },
  mounted: function () {
    this.getOrders()
  }
};
</script>
