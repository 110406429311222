<template>
  <div>
    <create-customer v-if="canManageCustomers" />
    <table class="table" v-if="customers.length > 0">
      <tr>
        <th>Meno zákaznika</th>
        <th>Email</th>
        <th>telefon</th>
        <th>Kontaktná osoba</th>
      </tr>
      <tbody>
      <tr v-for="(customer, key) in customers" v-bind:key="key">
        <td>{{ customer.name }}</td>
        <td>{{ customer.attributes.email }}</td>
        <td>{{ customer.attributes.phone_number }}</td>
        <td>{{ customer.attributes.contact_person }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import * as permissionsDefinitions from "../helpers/permissions"
import CreateCustomer from "../components/CreateCustomer";

export default {
  name: "Customers",
  components: {CreateCustomer},
  methods: {
    ...mapActions([
      "storeLogin",
      "storeRegister",
      "getCustomers",
    ]),
  },
  computed: {
    ...mapState(['customers', "loggedUser"]),  // assuming you are using namespaced modules
    canManageCustomers() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_CUSTOMERS_TOKEN_SCOPE)
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getCustomers()
  }
};
</script>
