<template>
  <div>
    <create-product v-if="canManageProducts"/>
    <table class="table" v-if="products.length > 0">
      <tr>
        <th>Nazov</th>
        <th>Typ</th>
        <th>C. Receptu</th>
      </tr>
      <tbody>
      <tr v-for="(product, key) in products" v-bind:key="key">
        <td>{{ product.name }}</td>
        <td>{{ product.attributes.type }}</td>
        <td>{{ product.attributes.recipe_number }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import CreateProduct from "../components/CreateProduct";
import * as permissionsDefinitions from "../helpers/permissions";
export default {
  name: "Products",
  components: {
    CreateProduct
  },
  methods: {
    ...mapActions(['getProducts'])
  },
  computed: {
    ...mapState(['products', 'loggedUser']),
    canManageProducts() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_PRODUCTS_TOKEN_SCOPE)
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getProducts()
  }
}
</script>

<style scoped>

</style>