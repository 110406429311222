<template>
  <div class="home">
HOME Screen
<!--    <router-view/>-->
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Home",

};
</script>
