<template>
  <div class="home">
    <user-edit v-if="editUser.id !== undefined "/>
    <table class="table" v-if="this.users">
      <th>Full Name</th>
      <th>Email</th>
      <th>Active</th>
      <th v-if="canEditUser">permissions</th>
      <th v-if="canEditUser">actions</th>
      <tbody>
      <tr v-for="(value, key) in filteredUsers" v-bind:key="key" >
        <td> {{ value.attributes.name }} {{ value.attributes.surname }}</td>
        <td>{{ value.email }}</td>
        <td>
          <p v-if="value.active" class="text-success ">active</p>
          <p v-else class="text-danger bold"> inactive</p>
        </td>
        <td v-if="canEditUser">
          <span v-if="value.permissions">
            <p v-for="id in value.permissionsIds" v-bind:key="id">{{permissions[id].description}},</p>
          </span>
          <p v-else> No permissions</p>
        </td>
        <td v-if="canEditUser"><p @click="setEditUser(value)" >edit</p></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import UserEdit from "../../components/UserEdit";
import  * as permDefinitions from "../../helpers/permissions"

export default {
  name: "Users",
  components: {UserEdit},
  methods: {
    ...mapActions([
      "getUsers","getPermissions", "setEditUser"
    ]),
  },
  computed: {
    ...mapState(['users', 'editUser', "permissions", "loggedUser"]),  // assuming you are using namespaced modules
    filteredUsers: function (){
      if(this.users instanceof Array){
        return this.users.filter((o) => {
          return o.id!==this.loggedUser.id
        })
      }
      return []
    },
    canEditUser() {
      return this.loggedUser.permissionsIds.includes(permDefinitions.MANAGE_USERS_TOKEN_SCOPE)
    },
  },
  mounted() {
    if (Object.keys(this.filteredUsers).length === 0) {
      this.getUsers()
    }
    if(!this.permissions){
      this.getPermissions();
    }
    // localStorage.removeItem("accessToken")
  }
};
</script>
