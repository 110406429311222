import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

Vue.config.productionTip = false;

Vue.mixin({
  methods: {
    getDateOfISOWeek(w, y, formatted = false) {
      var simple = new Date(y, 0, 1 + (w - 1) * 7);
      var dow = simple.getDay();
      var ISOweekStart = simple;
      if (dow <= 4)
        ISOweekStart.setDate(simple.getDate() - simple.getDay() + 1);
      else
        ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay());

      var end = new Date(JSON.parse(JSON.stringify(ISOweekStart)))
      end.setDate(end.getDate() + 6);

      if(!formatted){
        return {start: ISOweekStart, end: end}
      }
      return {
        start: ISOweekStart.toLocaleString('sk', {
          month: "numeric", day: "numeric",
        }),
        end: end.toLocaleString('sk', {
          month: "numeric", day: "numeric",
        })
      }
    }
  }
})
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

