<template>
  <div>
    <div>
      <ul class="list-style-unstyled">
        Zakaznik: {{data.customer.name}}<br>
        Zadal: {{data.user.full_name}}
        <li v-for="(value, key) in data.orderProducts" v-bind:key="key">
          Product: {{value.product.name}}<br>
          Planovane na tyzden od: {{getDateOfISOWeek(value.week, value.year).start}} {{value.year}}<br>
          Pozadovane mnozstvo: {{value.estimated_amount}}
        </li>
      </ul>
      Economy system identifier: <input type="text" v-model="economyOrderIdentifier">
    </div>
    <button class="btn btn-success" @click="store({identifier: economyOrderIdentifier, orders_id: data.order_id})">Eviduj!</button>
    <hide-modal-button/>
  </div>
</template>

<script>

import { mapActions, mapState } from "vuex";
import HideModalButton from "./HideModalButton";

export default {
  name: "EconomyOrders",
  components: {HideModalButton},
  props: ['data'],
  data: function () {
    return {
      economyOrderIdentifier: ""
    }
  },
  methods: {
    ...mapActions(['storeEconomyOrder']),
    store(payload) {
      this.economyOrderIdentifier = ""
      this.storeEconomyOrder(payload)
    }
  },
  computed: {
    ...mapState(['orders'])
  },
}
</script>

<style scoped>

</style>