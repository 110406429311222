import Vue from "vue";
import Vuex from "vuex";
import {localAxios} from "../helpers/localAxios";
import router from "../router";
import * as routes from "../helpers/routes";


Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        loading: false,
        customers: [],
        users: {},
        userDetail: {},
        loggedUser: {},
        editUser: {},
        permissions: [],
        orders: {},
        placingOrder: {
            customer_id: 0
        },
        products: {},
        plannedProducts: {},
        batches: {},
        showModal:false,
        archivedOrders: []
    },
    mutations: {
        toggleModal(state, data){
          state.showModal = data;
        },
        setLoading(state, data) {
            state.loading = data;
        },
        setCustomers(state, data) {
            state.customers = data;
        },
        setUsers(state, data) {
            state.users = data;
        },
        setEditUser(state, data) {
            state.editUser = data;
        },
        setPermissions(state, data) {
            state.permissions = data;
        },
        setLoggedUser(state, data) {
            Vue.set(state, "loggedUser", data)
            router.replace({path: '/'})
        },
        setCurrentUser(state, data) {
            Vue.set(state, "loggedUser", data)
        },
        setUserDetail(state, data) {
            state.userDetail = data;
        },
        updateUsers(state, data) {
            let index = state.users.findIndex(function (o) {
                return o.id === data.id
            })
            Vue.set(state.users, index, data)
        },
        storeOrders(state, data) {
            state.orders = data;
        },
        updateOrder(state, data) {
            let index = state.orders.findIndex(function (o) {
                return o.order_id === data.order_id
            })

            Vue.set(state.orders, index, data)
        },
        archiveOrder(state, data) {
            let index = state.orders.findIndex(function (o) {
                return o.order_id === data
            })
            state.orders.splice(index, 1)
        },
        storeProducts(state, data) {
            state.products = data.data;
            if (data.setPlacing) {
                console.log(state.placingOrder)
                state.placingOrder.products = [];
                Object.keys(state.products).forEach((o) => {
                    console.log(state.products[o].name)
                    state.placingOrder.products[state.products[o].id] = {
                        estimated_amount: 0
                    }
                })
                console.log(state.placingOrder)
            }
        },
        pushOrder(state, data) {
            state.orders.splice(0, 0, data)
        },
        setBatches(state, data) {
            state.batches = data;
        },
        pushBatch(state, data) {
            state.batches.splice(0, 0, data)
        },
        pushCustomer(state, data) {
            state.customers.splice(0, 0, data)
        },
        pushProduct(state, data) {
            state.products.splice(0, 0, data)
        },
        setPlannedProducts(state, data){
            state.plannedProducts = data;
        },
        setArchivedOrders(state, data) {
            state.archivedOrders = data;
        }
    },
    actions: {
        logout({commit}) {
            localStorage.removeItem("accessToken")
            commit("setCurrentUser", {})
            router.replace('/login')
        },
        storeLogin({commit}, payload) {
            localAxios.post(routes.USER + "/login", payload)
                .then((data) => {
                    if (data.access_token) {
                        localStorage.accessToken = data.access_token;
                        commit("setLoggedUser", data.user)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })

        },
        storeRegister({commit}, payload) {
            localAxios.post(routes.USER, payload)
                .then((data) => {
                    console.log(data, commit)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getCustomers({commit}) {
            let j = "jozo"
            console.log(j)
            localAxios.get(routes.CUSTOMER + "/all")
                .then((data) => {
                    console.log(data)
                    commit("setCustomers", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getUsers({commit, state}, payload = "all") {
            if (Object.keys(state.loggedUser).length !== 0 && payload === 'current') {
                return
            }
            localAxios.get(routes.USER + '/' + payload)
                .then((data) => {
                    if (payload === "all") {
                        commit("setUsers", data)
                    } else if (payload === "current") {
                        commit("setCurrentUser", data);
                    } else {
                        commit("setUserDetail", data);
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        setEditUser({commit}, data) {
            commit('setEditUser', Object.assign({}, data));
        },
        updateUser({commit, state}, user = null) {
            if (user == null) {
                user = state.editUser;
            }
            localAxios.put(routes.USER, user)
                .then((data) => {
                    commit("updateUsers", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getPermissions({commit}) {
            localAxios.get(routes.PERMISSION)
                .then((data) => {
                    commit('setPermissions', data)
                })
                .catch((err) => {
                    console.log(err);
                })
        },
        getOrders({commit}) {
            localAxios.get(routes.ORDER + "/all")
                .then((data) => {
                    commit("storeOrders", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getProducts({commit, state}, setPlacing) {
            if (Object.keys(state.products).length === 0) {
                localAxios.get(routes.PRODUCT + "/all")
                    .then((data) => {
                        commit("storeProducts", {data: data, setPlacing: setPlacing})
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            }
        },
        placeOrder({commit, state}, payload) {
            var postData = state.placingOrder;
            postData.products = payload;
            console.log(postData)
            postData.estimated_delivery = (new Date(postData.estimated_delivery)).getTime();
            console.log(postData)
            localAxios.post(routes.ORDER, postData)
                .then((data) => {
                    console.log(data)
                    commit("pushOrder", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getArchivedOrders({commit}) {
            localAxios.get(routes.ORDER + "/archived")
                .then((data) => {
                    commit('setArchivedOrders', data)
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        archiveOrder({commit}, payload) {
            localAxios.put(routes.ORDER + "/archive/" + payload.order_id, {attributes: payload.attributes})
                .then((data) => {
                    commit('archiveOrder', data)
                    commit('toggleModal', false)
                })
                .catch((err) => {
                    console.log(err)
                });
        },
        storePlannedOrder({commit}, payload) {
            console.log(payload)
            let products = {};
            payload.orderProducts.forEach((o) => {
                products[o.product.product_id] = {
                    "year": o.year,
                    "week": o.week,
                }
            })

            let postData = {
                order_id: payload.order_id,
                products: products
            }
            console.log(postData)
            localAxios.put(routes.ORDER + "/plan", postData)
                .then((data) => {
                    commit('updateOrder', data)
                    commit("toggleModal", false);
                })
                .catch((err) => {
                    console.log(commit, err)
                })
        },
        storeEconomyOrder({commit}, payload) {
            console.log(commit, payload)
            localAxios.put(routes.ORDER + "/set_economy", payload)
                .then((data) => {
                    console.log(data)
                    commit('updateOrder', data)
                    commit('toggleModal',false)
                })
                .catch((err) => {
                    console.log(commit, err)
                })
        },
        getBatches({commit}) {
            localAxios.get(routes.BATCH + "/all")
                .then((data) => {
                    commit("setBatches", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        storeBatch({commit}, payload) {
            localAxios.post(routes.BATCH, payload)
                .then((data) => {
                    commit("pushBatch", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        storeBatchOrder({commit}, payload) {
            localAxios.post(routes.ORDER + "/batch", payload)
                .then((data) => {
                    commit("updateOrder", data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        storeCustomer({commit}, payload) {
            localAxios.post(routes.CUSTOMER, payload)
                .then((data) => {
                    commit('pushCustomer', data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        storeProduct({commit}, payload) {
            localAxios.post(routes.PRODUCT, payload)
                .then((data) => {
                    commit('pushProduct', data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getPlanned({commit}) {
            localAxios.get(routes.PRODUCT + '/planned')
                .then((data) => {
                    commit('setPlannedProducts', data)
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    getters: {

    },
    modules: {},
});
