import axios from "axios"
import router from "../router";
import store from "../store";

const config = {
    baseURL: "https://api.panaraplast.com/api/"
    // timeout: 60 * 1000, // Timeout
    // withCredentials: true, // Check cross-site Access-Control
}

const _axios = axios.create(config)

_axios.interceptors.request.use(function (config) {
        store.commit('setLoading', true)
        config.headers.Authorization = "Bearer " + localStorage.accessToken;
        return config;
    },
    function (error) {
        console.log(error)
    });

_axios.interceptors.response.use(function (response) {
        store.commit('setLoading', false)
        return response.data.data;
    },
    function (error) {
        store.commit('setLoading', false)
        if (error.response.status === 401) {
            localStorage.removeItem('accessToken')
            router.replace("/login")
        } else {
            alert(error.response.data.error)
            return Promise.reject(error.response.data.error)
        }
    });

export const localAxios = _axios;
