import Vue from "vue";
import VueRouter from "vue-router";
import LoginPage from "../views/LoginPage.vue";
import Home from "../views/Home.vue";
import Orders from "../views/Orders/Orders.vue";
import Customers from "../views/Customers.vue";
import Users from "../views/Users/Users";
// import EconomyOrders from "../components/EconomyOrder"
import Batches from "../views/Batches";
import Products from "../views/Products";
import Planning from "../views/Planning";
import ArchivedOrders from "../views/Orders/ArchivedOrders";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/orders",
    name: "Orders",
    component: Orders,
  },
  {
    path: "/archive",
    name: "ArchivedOrders",
    component: ArchivedOrders,
  },
  // {
  //   path: "/economy-orders",
  //   name: "EconomyOrders",
  //   component: EconomyOrders,
  // },
  {
    path: "/batches",
    name: "Batches",
    component: Batches,
  },
  {
    path: "/customers",
    name: "Customers",
    component: Customers,
  },
  {
    path: "/products",
    name: "Products",
    component: Products,
  },
  {
    path: "/users",
    name: "Users",
    component: Users,
  },
  {
    path: "/planning",
    name: "Planning",
    component: Planning,
  },
  {
    path: "/",
    name: "Home",
    component: Home
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () =>
    //   import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
];

const router = new VueRouter({
  routes,
  // mode: "history"
});

export default router;
