<template>
  <div v-if="Object.keys(plannedProducts).length > 0">
    <div v-for="(weeks, year) in plannedProducts" v-bind:key="year" class="text-center w-50 m-auto border">
      Rok: {{ year }}<br>
      <div v-for="(orderProducts, week) in weeks" v-bind:key="week" class="text-start ps-1">
        <span class="fw-bold">
          Tyzden Od {{ getDateOfISOWeek(week, year, true).start }} Do {{ getDateOfISOWeek(week, year, true).end }}:
        </span>
        <!--        {{ sumAmounts(orderProducts) }}-->
        <ul class="list-unstyled ps-4">
          <li v-for="(orderProduct, index) in orderProducts" v-bind:key="index">
            {{ orderProduct.product.name }} ( {{ orderProduct.product.attributes.type }} /
            {{ orderProduct.product.attributes.recipe_number }} ),
            {{ orderProduct.estimated_amount }} - {{ orderProduct.order.customer.name }}
          </li>
        </ul>
        <br>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";

var lastPrintedYear = 0;
export default {
  name: "Planning",
  methods: {
    ...mapActions(['getPlanned']),
    sumAmounts(orderProducts) {
      return orderProducts.reduce(function (accumulator, item) {
        return accumulator + item.estimated_amount;
      }, 0)
    },
    weeksCount: function (year) {
      // return year
      return this.plannedProducts.filter((o) => o.year === year).length
    },
    printYear: function (year) {
      if (year !== lastPrintedYear) {
        lastPrintedYear = year;
        return true
      }
      return false
    },
  },
  computed: {
    ...mapState(['plannedProducts']),
  },
  mounted: function () {
    this.getPlanned()
  },
}
</script>

<style scoped>

</style>