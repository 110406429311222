<template>
  <div class="home">
    <div v-if="canCreateBatch">
      <ul class="list-unstyled">
        Create a Batch
        Product:<select v-model="batchProduct">
        <option v-for="(product, key) in this.products" v-bind:key="key" :value="product.product_id">
          {{ product.name }}
        </option>
      </select>
        Amount <input type="number" v-model="amount">
        Batch Identifier <input type="text" v-model="identifier">
        <button @click="storeBatch({products_id: batchProduct, identifier: identifier, amount:amount})">Vyrobit</button>
      </ul>
    </div>

    <table class="table">
      <thead>
      <tr>
        <th>Identifier</th>
        <th>Amount</th>
        <th>Amount - left</th>
        <th>Product</th>
        <th>Produced By</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(batch, key) in batches" v-bind:key="key">
        <td>{{ batch.identifier }}</td>
        <td>{{ batch.amount }}</td>
        <td>{{ batch.amount_left }}</td>
        <td>{{ batch.product.name }}</td>
        <td>{{ batch.created_by.full_name }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
// @ is an alias to /src
import {mapActions, mapState} from "vuex"
import * as permissionsDefinitions from "../helpers/permissions"
export default {
  name: "Batches",
  data: function () {
    return {
      batchProduct: null,
      identifier: "",
      amount: 0,
    }
  },
  methods: {
    ...mapActions([
      "storeLogin",
      "storeRegister",
      "getBatches",
      "getProducts",
      "storeBatch",
    ]),
  },
  computed: {
    ...mapState(['batches', 'products', "loggedUser"]),  // assuming you are using namespaced modules
    canCreateBatch() {
      if (this.loggedUser.permissionsIds !== undefined) {
        return this.loggedUser.permissionsIds.includes(permissionsDefinitions.MANAGE_BATCHES_TOKEN_SCOPE);
      } else {
        return false;
      }
    }
  },
  mounted() {
    this.getBatches()
    this.getProducts()
  }
};
</script>
