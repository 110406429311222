<template>
  <div>
    <div class="row w-25 m-auto">
      <div class="col">
        <input type="text" class="form-control" v-model="product.name" placeholder="Nazov">
        <input type="text" class="form-control" v-model="product.attributes.type" placeholder="Typ">
        <input type="text" class="form-control" v-model="product.attributes.recipe_number" placeholder="C.Receptu">
      </div>
    </div>
    <button @click="storeProduct(product)" class="btn btn-success mt-1">Uložiť</button>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "CreateProduct",
  data: function () {
    return {
      product: {
        name: "",
        attributes: {
          type: "",
          recipe_number: "",
        }
      }
    }
  },
  methods: {
    ...mapActions(['storeProduct'])
  }
}
</script>

<style scoped>

</style>