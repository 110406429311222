<template>
  <div>
    <h1>Vytvoriť zákazníka</h1>
    <div class="row w-50 m-auto">
      <div class="col">
        <input type="text" class="form-control" placeholder="Meno zákaznika" v-model="customer.name">
      </div>
      <div class="col">
        <input type="text" class="form-control" placeholder="Email" v-model="customer.attributes.email">
      </div>
    </div>
    <div class="row w-50 m-auto mt-1">

      <div class="col">
        <input type="text" class="form-control" placeholder="Telefon" v-model="customer.attributes.phone_number">
      </div>
      <div class="col">
        <input type="text" class="form-control" placeholder="Kontaktná osoba"
               v-model="customer.attributes.contact_person">
      </div>
    </div>
    <button @click="store(customer)" class="btn btn-success mt-1">Uložiť</button>
  </div>
</template>

<script>

import {mapActions} from "vuex";

export default {
  name: "CreateCustomer",
  data: function () {
    return {
      customer: {
        name: "",
        attributes: {
          email: "",
          phone_number: "",
          contact_person: ""
        }
      }
    }
  },
  methods: {
    store() {
      this.storeCustomer(this.customer)
      this.customer = {
        name: "",
        attributes: {
          email: "",
          phone_number: "",
          contact_person: ""
        }
      }
    },
    ...mapActions(['storeCustomer'])
  }
}
</script>

<style scoped>

</style>