<template>
  <div id="app">
    <div v-if="this.loading" id="loading">
      <p>
        Loading content...
      </p>
    </div>
    <div v-if="this.$route.fullPath !== '/login' && Object.keys(this.loggedUser).length !== 0">

      <div id="nav" >
        Prihlaseny ako: <p>{{loggedUser.full_name}}</p>
        <router-link to="/">Home</router-link> |
        <router-link to="/customers">Customers</router-link> |
        <router-link to="/orders">Orders</router-link> |
        <router-link to="/archive">Archived Orders</router-link> |
        <!--        <router-link to="/economy-orders">Economy Orders</router-link>-->
        <router-link to="/products">Products</router-link> |
        <router-link to="/planning">Planning</router-link> |
        <router-link to="/batches">Batches</router-link> |
        <router-link to="/users">Users</router-link> |
        <a @click="this.logout">logout</a>
      </div>
    </div>
    <router-view/>
  </div>
</template>
<script>
import router from "./router";
import {mapActions, mapState} from "vuex";
import * as permissionsConstants from './helpers/permissions'

export default {
  data: function () {
    return {
      permissionsConstants: permissionsConstants
    }
  },
  methods: {
    ...mapActions(['getPermissions', 'getUsers', 'logout']),
  },
  computed: {
    ...mapState(['permissions', 'loggedUser', 'loading'])
  },
  mounted() {
    if (!localStorage.accessToken) {
      this.logout()
    } else {
      this.getUsers("current")
      this.getPermissions()
      router.replace(this.$route.path)
    }
  }
}

</script>

<style>
#loading {
  position: absolute;
  width: 100%;
  height: 100%;
  display: block;
  background: rgba(200, 200, 200, 0.5);
}

#loading > p {
  position: absolute;
  display: block;
  top: 50%;
  left: 50%;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
