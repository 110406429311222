<template>
  <div class="p-5">
    <h2>Detail objednávky</h2>
    <ul class="list-group list-unstyled text-start">
      <li class="list-group-item">Stav: {{ data.state }}</li>
      <li class="list-group-item">Pridal: {{ data.user.full_name }}</li>
      <li class="list-group-item">Cislo zakazky:
        {{ data.economy_order !== undefined ? data.economy_order.identifier : " - " }}
      </li>
      <li class="list-group-item">
        Produkty:
        <ul class="list-group list-unstyled">
          <li class="list-group-item" v-for="(orderProduct, key) in data.orderProducts" v-bind:key="key">
            {{ orderProduct.product.name }}:
            <ul class="list-group list-unstyled">
              <li class="list-group-item">Pozadovane mnozstvo: {{orderProduct.estimated_amount}}</li>
              <li class="list-group-item">
                Typ/c.receptu: {{ orderProduct.product.attributes.type }}/{{ orderProduct.product.attributes.recipe_number }}<br>
                  <p>Sarze:</p>
                <span v-for="(batch, key) in orderProduct.batches" v-bind:key="key">id: <b>{{batch.identifier}}</b> - {{batch.ordersAmounts[data.order_id]}}kg <br>
              </span>
              </li>
              <li class="list-group-item" v-if="orderProduct.year">
                Vyroba naplanovana v tyzdni od: {{getDateOfISOWeek(orderProduct.week, orderProduct.year).start}} {{ orderProduct.year }}
              </li>
              <li v-else>Výroba nenaplánovaná</li>
            </ul>

          </li>
        </ul>
      </li>
      <li class="list-group-item">
        Zakaznik:
        <ul class="list-group list-unstyled">
          <li class="list-group-item">meno: {{ data.customer.name }}</li>
          <li class="list-group-item" v-for="(attribute, key) in data.customer.attributes" v-bind:key="key">
            {{ key }}: {{ attribute }}
          </li>
        </ul>
      </li>
    </ul>
    <hide-modal-button/>
  </div>
</template>

<script>
import HideModalButton from "./HideModalButton";
export default {
  components: {HideModalButton},
  props: ['data'],
  name: "OrderDetail",
}
</script>

<style scoped>

</style>