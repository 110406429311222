<template>
  <div>
    <div class="w-25  m-auto">
      <h1>Vytvorit objednavku</h1>
      <select class="form-select" v-model="placingOrder.customer_id" v-if="this.customers.length > 0">
        <option selected value="0">Zvolit zákazníka</option>
        <option v-for="(customer, key) in this.customers" v-bind:key="key" :value="customer.id">
          {{ customer.name }}
        </option>
      </select>
      <p v-else>Nemate ziadnych zakaznikov v systeme</p>
      <div class="list-unstyled" v-if="products.length > 0">
        <h4>Zvoliť produkty</h4>
          <div class="form-check" v-for="(product, id) in products" v-bind:key="id">
            <label class="form-check-label" >
              {{ product.name }}
            </label>
            <input type="checkbox" :value="product.product_id" v-model="placingProducts">
            <span v-if="placingProducts.includes(product.product_id)">
            <input class="form-control" type="number" v-model="placingAmounts[product.product_id]" placeholder="Požadované množstvo[kg]">
          </span>

          </div>

        Požadovaný termín výroby: <input class="form-control w-50 m-auto" type="date" v-model="placingOrder.estimated_delivery">
      </div>
      <div v-else> Nemate ziadne produkty v systeme</div>
      <button v-if="canOrder" class="btn btn-success" @click="placeOrder(productsEstimate)">Zadať objednávku!</button>
    </div>
  </div>
</template>

<script>
import {mapState, mapActions} from "vuex";

export default {
  name: "StoreOrder",
  data: function () {
    return {
      placingProducts: [],
      placingAmounts: {}
    }
  },
  methods: {
    ...mapActions(["getCustomers", "getProducts", "placeOrder"])
  },
  computed: {
    ...mapState(["customers", "placingOrder", 'products']),
    canOrder() {
      return this.customers.length > 0 && this.products.length > 0
    },
    productsEstimate: function () {
      let result = {};
      this.placingProducts.forEach((o) => {
        result[o] = {estimated_amount: this.placingAmounts[o]}
      })
      return result;
      // return this.placingProducts.map((o) => Object.assign({}, {[o]: {estimated_amount: this.placingAmounts[o]} }))
    }
  }
  ,
  mounted: function () {
    this.getCustomers()
    this.getProducts(true)
  }
}
</script>

<style scoped>

</style>