<template>
  <div style="">
    <div>
      <ul class="list-unstyled">
        <li>Email: {{editUser.email}}</li>
        <li>Name: {{ editUser.attributes.name }}</li>
        <li>Surname: {{ editUser.attributes.surname }}</li>
        <li>Phone Number: {{ editUser.attributes.phone_number }}</li>
        <li>Active: <input type="checkbox" v-model="editUser.active">
        </li>
        <div >
          <b>Permissions:</b>
          <ul class="list-unstyled">
            <li v-for="(permission, id) in permissions" v-bind:key="id">
              {{ permission.description }} <input type="checkbox" :value="permission.name" v-model="editUser.permissionsIds">
            </li>
          </ul>
        </div>
        <li>
          <button :class="'btn btn-success'" @click="updateUser(editUser)"> Save changes</button>
          <button :class="'btn btn-secondary'" @click="setEditUser({})"> Hide</button>
        </li>
      </ul>
    </div>

  </div>
</template>

<script>

import {mapState, mapActions} from "vuex";

export default {
  name: "UserEdit",
  data: function () {
    return {
      showPermissions: false,
    }
  },
  methods: {
    ...mapActions(['updateUser', 'setEditUser']),
  },
  computed: {
    ...mapState(['editUser', 'permissions']),
  }
}
</script>

<style scoped>

</style>